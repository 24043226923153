import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import Layout from '../components/Layout'
import TitleBar from '../components/TitleBar/TitleBar'
import SEO from '../components/SEO'

export const ServiceTemplate = ({ service, testimonial }) => (
  <div className="service-wrapper has-text-centered">
    <section>
      <div className="container">
        <div className="columns">
          <div className="column is-10 is-offset-1">
            <div className="section">
              <Img
                className="service-banner-image"
                fluid={service.featured_media.localFile.childImageSharp.fluid}
              />
              <div
                className="content"
                dangerouslySetInnerHTML={{ __html: service.content }}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className="testimonial-wrapper">
      <div className="container">
        <div className="columns">
          <div className="column is-10 is-offset-1">
            <div className="section">
              <Img
                fixed={
                  testimonial.featured_media.localFile.childImageSharp.fixed
                }
              />
              <div
                className="content"
                dangerouslySetInnerHTML={{ __html: testimonial.content }}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
)

const Service = ({ data }) => {
  console.log(data)
  const {
    wordpressWpServices: service,
    wordpressWpTestimonials: testimonial,
  } = data

  return (
    <Layout>
      <SEO title={service.title} />
      <TitleBar title={service.title} />
      <ServiceTemplate service={service} testimonial={testimonial} />
    </Layout>
  )
}

export default Service

export const ServiceQuery = graphql`
  query ServiceById($id: String!, $testimonialId: Int!) {
    wordpressWpServices(id: { eq: $id }) {
      title
      content
      featured_media {
        localFile {
          childImageSharp {
            fluid(maxWidth: 700, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    wordpressWpTestimonials(wordpress_id: { eq: $testimonialId }) {
      title
      content
      featured_media {
        localFile {
          childImageSharp {
            fixed(width: 300, height: 180, quality: 100) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    }
  }
`
